import React, { useState } from "react";
import {
  ListItemIcon,
  ListItemText,
  Avatar,
  List,
  ListItem,
  IconButton
} from "@mui/material";
import { useQuery, useMutation } from "@apollo/client";
import GET_INFLUENCER_FOR_CHAT from "../../graphql/queries/getInfluencerForChat";
import UPDATE_CHATROOM_STATUS from "../../graphql/mutations/updateChatroomStatus";
import { toast } from "react-toastify";
import { CircularLoader } from "../common-components/loaders";
import DeleteIcon from '@mui/icons-material/Delete';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import useMediaQuery from '@mui/material/useMediaQuery';
import DeleteConfirmationDialog from './deletePopup';

const ChatRoomTile = ({
  influencerId,
  selectChat,
  campaignName,
  selected,
  chatroom
}) => {
  const [isChatActive, setIsChatActive] = useState(chatroom?.active);
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const [deletePopup, setDeletePopup] = useState(false)

  const { data } = useQuery(GET_INFLUENCER_FOR_CHAT, {
    variables: {
      userId: influencerId,
    },
  });

  const [updateChatroomStatus] = useMutation(UPDATE_CHATROOM_STATUS, {
    onCompleted: (data) => {
      let status = data?.updateChatroomStatus?.active ? "Unblocked" : "Blocked"
      toast.success(`Chat ${status} Successfully`);
      setIsChatActive(data?.updateChatroomStatus?.active)
    },
  });

  const handleUpdateStatus = (chat) => {
    updateChatroomStatus({
      variables: {
        chatroomId: chat?.id,
        active: !isChatActive
      }
    });
  };

  if (data === undefined) return <CircularLoader title="Loading Chat..." />;
  return (
    <List
      key={`${data.getInfluencer.id}-${data.getInfluencer.name}`}
      sx={{
        marginBottom: 1,
        paddingTop: 0,
        backgroundColor: selected ? "#E0E0E0" : "##FFF",
        borderRadius: 2,
        "&:hover": { backgroundColor: "#f0eded", cursor: "pointer" },
        width: '98.5%',
        borderBottom: 'solid 2px #E0E0E0'
      }}
      onClick={() => selectChat(chatroom.id, data.getInfluencer)}
    >
      <ListItemText
        primary={campaignName}
        secondary={isSmallScreen && data?.getInfluencer?.name}
        sx={{
          color: "#FFF",
          backgroundColor: "#FFA3C7",
          px: 1,
          borderTopRightRadius: 4,
          borderTopLeftRadius: 4,
        }}
      />
      <ListItem sx={{display: 'flex', flexWrap: 'wrap'}}>
        {!isSmallScreen && <ListItemIcon>
          <Avatar
            alt={data.getInfluencer.name}
            src={data.getInfluencer.image}
          />
        </ListItemIcon>}
        <ListItemText
          primary={data?.getInfluencer?.name}
          secondary={chatroom?.lastMessage?.text}
        />
        <IconButton
          color="#FFF"
          aria-label="delete"
          onClick={(event) => {
            event.stopPropagation();
            setDeletePopup(true)
          }}
          sx={{objectFit: 'contain', color: "purple"}}
        >
          <DeleteIcon />
        </IconButton>
        <IconButton
          color="#FFF"
          aria-label="block"
          onClick={(event) => {
            event.stopPropagation();
            handleUpdateStatus(chatroom);
          }}
          sx={{objectFit: 'contain'}}
        >
          {isChatActive ? <ToggleOnIcon /> : <ToggleOffIcon />}
        </IconButton>
      </ListItem>
      {deletePopup && (
        <DeleteConfirmationDialog
          open={deletePopup}
          setOpen={setDeletePopup}
          chatroomId={chatroom?.id}
        />
      )}
    </List>
  );
};

export default ChatRoomTile;

import { Grid, Typography, Tabs, Tab } from "@mui/material";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import React, { useEffect, useState } from "react";
import GraphCard from "./graphCard";
import { useQuery } from "@apollo/client";
import {
  GET_BRAND_CAMPAIGN_NAMES,
  GET_BRAND_CAMPAIGN_USERS_DATA,
} from "../../graphql/queries/getBrandCampaignNames";
// import Chart from "react-apexcharts";
import { makeStyles } from "@mui/styles";
import CampaignBanner from "../../assets/campaign-result-banner.jpeg"
import GET_INFLUENCER_FOR_GRAPH from "../../graphql/queries/getInfluencerForGraph";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
      <div
          role="tabpanel"
          hidden={value !== index}
          id={`simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          {...other}
      >
        {value === index && (
            <Box sx={{ p: 3 }}>
              <Typography>{children}</Typography>
            </Box>
        )}
      </div>
  );
};

const useStyles = makeStyles({
  banner: {
    height: "25vh",
    padding: "5px 5px 5px 5px",
    width: "97%",
    backgroundImage: `url(${CampaignBanner})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
    color: "#2d3436",
    marginBottom: 40,
    objectFit:"contain"
  },
})

const CampaignResult = () => {
  const [campaign, setCampaign] = useState(null);
  const [results, setResults] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0);
  const classes = useStyles();
  const [influencer, setInfluencer] = useState(null);

  const { data: userData, refetch: refetchUserData } = useQuery(
      GET_BRAND_CAMPAIGN_USERS_DATA,
      {
        skip: campaign === null,
        variables: {
          campaignId: campaign,
          userId: influencer
        },
        onCompleted: (res) => {
          res.getBrandCampaignUsersData &&
          setResults(res.getBrandCampaignUsersData.results);
        },
      }
  );

  const { data: influencersData } = useQuery(GET_INFLUENCER_FOR_GRAPH, {
    skip: campaign === null,
    variables: {
      campaignIds: campaign ? [campaign] : [],
    },
  });

  useEffect(() => {
    if (campaign !== null && influencer !== null) {
      refetchUserData();
    }
  }, [campaign, influencer, refetchUserData]);

  const { data: _campaigns } = useQuery(GET_BRAND_CAMPAIGN_NAMES, {
    variables: {
      brandId: localStorage.getItem("currentUser"),
    },
  });

  const instaSums = results !== null ? {
    likes: results.reduce((acc, row) => acc + (row.type === 'instagram' ? row.instagram[0] : 0), 0),
    comments: results.reduce((acc, row) => acc + (row.type === 'instagram' ? row.instagram[1] : 0), 0),
    reach: results.reduce((acc, row) => acc + (row.type === 'instagram' ? row.instagram[2] : 0), 0),
    views: results.reduce((acc, row) => acc + (row.type === 'instagram' ? row.instagram[3] : 0), 0),
    impressions: results.reduce((acc, row) => acc + (row.type === 'instagram' ? row.instagram[4] : 0), 0),
    followers: results.reduce((acc, row) => acc + (row.type === 'instagram' ? row.instagram[5] : 0), 0),
    shares: results.reduce((acc, row) => acc + (row.type === 'instagram' ? row.instagram[6] : 0), 0),
    saves: results.reduce((acc, row) => acc + (row.type === 'instagram' ? row.instagram[7] : 0), 0)

  } : null;

  const tiktokSums = results !== null ? {
    likes: results.reduce((acc, row) => acc + (row.type === 'tiktok' ? parseInt(row.tiktok[0]) : 0), 0),
    comments: results.reduce((acc, row) => acc + (row.type === 'tiktok' ? parseInt(row.tiktok[1]) : 0), 0),
    reach: results.reduce((acc, row) => acc + (row.type === 'tiktok' ? parseInt(row.tiktok[2]) : 0), 0),
    views: results.reduce((acc, row) => acc + (row.type === 'tiktok' ? parseInt(row.tiktok[3]) : 0), 0),
    plays: results.reduce((acc, row) => acc + (row.type === 'tiktok' ? parseInt(row.tiktok[4]) : 0), 0),
    shares: results.reduce((acc, row) => acc + (row.type === 'tiktok' ? parseInt(row.tiktok[5]) : 0), 0),
    saves: results.reduce((acc, row) => acc + (row.type === 'tiktok' ? parseInt(row.tiktok[6]) : 0), 0),

  } : null;

  // const handleChange = (event) => {
  //   setCampaign(0);
  // };

  // function calculateReach() {
  //   let points = 0;

  //   campaigns[campaign]?.results?.forEach(
  //     (result) => (points += result[1] + result[2])
  //   );
  //   return (points / (campaigns[campaign]?.totalFollowers + 18000)) * 100;
  // }
  // function calculateRoi() {
  //   let points = 0;

  //   campaigns[campaign]?.results?.forEach(
  //     (result) => (points += result[1] + result[2])
  //   );
  //   return (points / (campaigns[campaign]?.totalFollowers + 5000)) * 100;
  // }

  // var radialBarOptions = {
  //   chart: {
  //     height: 280,
  //     type: "radialBar",
  //   },

  //   plotOptions: {
  //     radialBar: {
  //       hollow: {
  //         margin: 15,
  //         size: "70%",
  //       },

  //       dataLabels: {
  //         showOn: "always",
  //         name: {
  //           offsetY: -10,
  //           show: true,
  //           color: "#fff",
  //           fontSize: "13px",
  //         },
  //         value: {
  //           color: "#fff",
  //           fontSize: "30px",
  //           show: true,
  //         },
  //       },
  //     },
  //   },

  //   stroke: {
  //     lineCap: "round",
  //   },
  // };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  useEffect(() => {
    // getBrandCampaigns().then((data) => {
    //   let inProgressCampaigns = data?.filter(
    //     (c) => c?.status === "start" && c?.results?.length > 0
    //   );
    //   inProgressCampaigns = inProgressCampaigns?.map((c) => {
    //     c.results = c.results?.filter(
    //       (r) => r?.status?.toLowerCase() === "approved"
    //     );
    //     return c;
    //   });
    //   // setCampaigns(inProgressCampaigns);
    //   let categorizedInstaTikTok = inProgressCampaigns?.map((c) => {
    //     c.results_categories = c.results.reduce((prev, curr) => {
    //       prev[curr.type] = curr; // instagram or tiktok
    //       prev[curr.type].value_categories = curr.values?.reduce(
    //         (group, value) => {
    //           const category = value?.field?.category?.name;
    //           group[category] = group[category] ?? [];
    //           group[category].push(value);
    //           return group;
    //         },
    //         {}
    //       );
    //       for (let cat in prev[curr.type].value_categories) {
    //         prev[curr.type].value_categories[cat] = prev[
    //           curr.type
    //         ].value_categories[cat].reduce((cat_val, curr) => {
    //           if (!cat_val.find((temp) => temp?.field === curr?.field?.name)) {
    //             return [
    //               ...cat_val,
    //               { field: curr?.field?.name, total: curr?.value || 0 },
    //             ];
    //           }
    //           cat_val = cat_val?.map((temp) =>
    //             temp?.field === curr?.field?.name
    //               ? { ...temp, total: temp.total + (curr.value || 0) }
    //               : temp
    //           );
    //           return cat_val;
    //         }, []);
    //       }
    //       return prev;
    //     }, {});
    //     return c;
    //   });
    //   setCampaigns(categorizedInstaTikTok);
    //   setSelectedCampaign(categorizedInstaTikTok[0] || null);
    // });
  }, []);

  return (
      <Grid
          item
          xs={12}
          container
          sx={{
            paddingLeft: "65px",
            paddingTop: 4,
            width: "100vw",
          }}
          justifyContent="center"
          alignItems="start"
      >

        <Box className={classes.banner}/>
        <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            aria-label="details-switch"
            sx={{ maxWidth: 280 }}
        >
          <Tab label="Tabular Data" />
          <Tab label="Graphs" />
        </Tabs>
        <Grid item xs={11} container justifyContent="space-between">
          <Typography variant="h4"> Campaign Results </Typography>
          <Box sx={{ minWidth: 200 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Campaign</InputLabel>
              <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={campaign || ""}
                  label="Campaign"
                  onChange={(e) => setCampaign(e.target.value)}
                  variant="standard"
              >
                {_campaigns?.getBrandCampaignNames &&
                    _campaigns.getBrandCampaignNames.map((campaign) => (
                        <MenuItem key={campaign.id} value={campaign.id}>
                          {campaign.name}
                        </MenuItem>
                    ))}
              </Select>
            </FormControl>
            <br/>
            <br/>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-influencer-label">Influencer</InputLabel>
              <Select
                  labelId="demo-simple-select-influencer-label"
                  id="demo-simple-select-influencer"
                  value={influencer || ""}
                  label="Influencer"
                  onChange={(e) => setInfluencer(e.target.value)}
                  variant="standard"
              >
                {influencersData?.getInfluencersForCampaigns &&
                    influencersData.getInfluencersForCampaigns.map((influencer) => (
                        <MenuItem key={influencer.id} value={influencer.id}>
                          {influencer.name}
                        </MenuItem>
                    ))}
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={11} sx={{ marginTop: 5 }}>
          <TabPanel value={selectedTab} index={0}>
            <TableContainer component={Paper} sx={{ marginBottom: 5 }}>
              <Typography textAlign="center" fontWeight="bold"> Instagram </Typography>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Campaign Type</TableCell>
                    <TableCell>Influencer</TableCell>
                    <TableCell align="center">Total Likes</TableCell>
                    <TableCell align="center">Total Comments</TableCell>
                    <TableCell align="center">Total Reach</TableCell>
                    <TableCell align="center">Total Plays</TableCell>
                    <TableCell align="center">Total Impressions</TableCell>
                    <TableCell align="center">Followers</TableCell>
                    <TableCell align="center">Total Shares</TableCell>
                    <TableCell align="center">Total Saves</TableCell>
                    <TableCell align="center">Discount Code Used</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {results !== null &&
                      results.map((row) => row.type === 'instagram' && (
                          <TableRow
                              key={row.id}
                              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                          >
                            <TableCell component="th" scope="row">
                              {row.date}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {row.campaign_type}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {row.name}
                            </TableCell>
                            <TableCell align="center">{row.instagram[0]}</TableCell>
                            <TableCell align="center">{row.instagram[1]}</TableCell>
                            <TableCell align="center">{row.instagram[2]}</TableCell>
                            <TableCell align="center">{row.instagram[3]}</TableCell>
                            <TableCell align="center">{row.instagram[4]}</TableCell>
                            <TableCell align="center">{row.instagram[5]}</TableCell>
                            <TableCell align="center">{row.instagram[6]}</TableCell>
                            <TableCell align="center">{row.instagram[7]}</TableCell>
                            <TableCell align="center">No</TableCell>
                          </TableRow>
                      ))}
                  {
                      instaSums !== null &&
                      <TableRow
                          sx={{ "&:last-child td, &:last-child th": { border: 0 }, fontWeight: 'bold' }}
                      >
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell component="th" scope="row"  sx={{ fontWeight: 'bold' }}>
                          Total
                        </TableCell>
                        <TableCell align="center" sx={{ fontWeight: 'bold' }}>{instaSums.likes}</TableCell>
                        <TableCell align="center" sx={{ fontWeight: 'bold' }}>{instaSums.comments}</TableCell>
                        <TableCell align="center" sx={{ fontWeight: 'bold' }}>{instaSums.reach}</TableCell>
                        <TableCell align="center" sx={{ fontWeight: 'bold' }}>{instaSums.views}</TableCell>
                        <TableCell align="center" sx={{ fontWeight: 'bold' }}>{instaSums.impressions}</TableCell>
                        <TableCell align="center" sx={{ fontWeight: 'bold' }}>{instaSums.followers}</TableCell>
                        <TableCell align="center" sx={{ fontWeight: 'bold' }}>{instaSums.shares}</TableCell>
                        <TableCell align="center" sx={{ fontWeight: 'bold' }}>{instaSums.saves}</TableCell>
                      </TableRow>
                  }
                </TableBody>
              </Table>
            </TableContainer>

            <TableContainer component={Paper} sx={{ marginBottom: 5 }}>
              <Typography textAlign="center" fontWeight="bold"> Tiktok </Typography>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Influencer</TableCell>
                    <TableCell align="center">Total Likes</TableCell>
                    <TableCell align="center">Total Comments</TableCell>
                    <TableCell align="center">Total Avg. Watch Time</TableCell>
                    <TableCell align="center">Total Watch Full Video Time</TableCell>
                    <TableCell align="center">Total Plays</TableCell>
                    <TableCell align="center">Total Shares</TableCell>
                    <TableCell align="center">Total Saves</TableCell>
                    <TableCell align="center">Discount Code Used</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {results !== null &&
                      results.map((row) => row.type === 'tiktok' &&  (
                          <TableRow
                              key={row.id}
                              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                          >
                            <TableCell component="th" scope="row">
                              {row.date}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {row.name}
                            </TableCell>
                            <TableCell align="center">{row.tiktok[0]}</TableCell>
                            <TableCell align="center">{row.tiktok[1]}</TableCell>
                            <TableCell align="center">{row.tiktok[2]}</TableCell>
                            <TableCell align="center">{row.tiktok[3]}</TableCell>
                            <TableCell align="center">{row.tiktok[4]}</TableCell>
                            <TableCell align="center">{row.tiktok[5]}</TableCell>
                            <TableCell align="center">{row.tiktok[6]}</TableCell>
                            <TableCell align="center">No</TableCell>
                          </TableRow>
                      ))}
                  {
                      tiktokSums !== null &&
                      <TableRow
                          sx={{ "&:last-child td, &:last-child th": { border: 0 }, fontWeight: 'bold' }}
                      >
                        <TableCell></TableCell>
                        <TableCell component="th" scope="row"  sx={{ fontWeight: 'bold' }}>
                          Total
                        </TableCell>
                        <TableCell align="center" sx={{ fontWeight: 'bold' }}>{tiktokSums.likes}</TableCell>
                        <TableCell align="center" sx={{ fontWeight: 'bold' }}>{tiktokSums.comments}</TableCell>
                        <TableCell align="center" sx={{ fontWeight: 'bold' }}>{tiktokSums.reach}</TableCell>
                        <TableCell align="center" sx={{ fontWeight: 'bold' }}>{tiktokSums.views}</TableCell>
                        <TableCell align="center" sx={{ fontWeight: 'bold' }}>{tiktokSums.plays}</TableCell>
                        <TableCell align="center" sx={{ fontWeight: 'bold' }}>{tiktokSums.shares}</TableCell>
                        <TableCell align="center" sx={{ fontWeight: 'bold' }}>{tiktokSums.saves}</TableCell>
                      </TableRow>
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </TabPanel>
        </Grid>
        <Grid
            item
            xs={11}
            container
            sx={{ marginTop: 3 }}
            justifyContent="center"
        >
          <TabPanel value={selectedTab} index={1}>
            {/* {campaign !== null && (
          <Card
            sx={{
              minWidth: 330,
              backgroundColor: "#000",
              marginTop: 3,
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <Chart
              options={{ ...radialBarOptions, labels: ["Engagement"] }}
              series={campaign !== null && [calculateReach().toFixed(2)]}
              type="radialBar"
              width={330}
            />
            <Chart
              options={{ ...radialBarOptions, labels: ["ROI"] }}
              series={campaign !== null && [calculateRoi().toFixed(2)]}
              type="radialBar"
              width={330}
            />
          </Card>
        )} */}

            {results !== null && influencer !== null  ? (
                <>
                  <GraphCard
                      title="Total Likes"
                      influencers={results.map((result) => result.name + " (" + result.date +")")}
                      numbers={[
                        {
                          name: 'Instagram Story',
                          data: results.map((result) => result.campaign_type === 'story' ? result.instagram[0] : 0),
                        },
                        {
                          name: 'Instagram Post',
                          data: results.map((result) => result.campaign_type === 'post' ? result.instagram[0] : 0),
                        },
                        {
                          name: 'Instagram Reels',
                          data: results.map((result) => result.campaign_type === 'reel' ? result.instagram[0] : 0),
                        },
                        {
                          name: 'Tiktok Story',
                          data: results.map((result) => result.campaign_type === 'story' ? result.tiktok[0] : 0),
                        },
                        {
                          name: 'Tiktok Post',
                          data: results.map((result) => result.campaign_type === 'post' ? result.tiktok[0] : 0),
                        },
                        {
                          name: 'Tiktok Reels',
                          data: results.map((result) => result.campaign_type === 'reel' ? result.tiktok[0] : 0),
                        },
                      ]}
                  />
                  <GraphCard
                      title="Total Comments"
                      numbers={[
                        {
                          name: 'Instagram Story',
                          data: results.map((result) => result.campaign_type === 'story' ? result.instagram[1] : 0),
                        },
                        {
                          name: 'Instagram Post',
                          data: results.map((result) => result.campaign_type === 'post' ? result.instagram[1] : 0),
                        },
                        {
                          name: 'Instagram Reels',
                          data: results.map((result) => result.campaign_type === 'reel' ? result.instagram[1] : 0),
                        },
                        {
                          name: 'Tiktok Story',
                          data: results.map((result) => result.campaign_type === 'story' ? result.tiktok[1] : 0),
                        },
                        {
                          name: 'Tiktok Post',
                          data: results.map((result) => result.campaign_type === 'post' ? result.tiktok[1] : 0),
                        },
                        {
                          name: 'Tiktok Reels',
                          data: results.map((result) => result.campaign_type === 'reel' ? result.tiktok[1] : 0),
                        },
                      ]}
                      influencers={results.map((result) => result.name + " (" + result.date +")")}
                  />
                  <GraphCard
                      title="Total Reach"
                      numbers={[
                        {
                          name: 'Instagram Story',
                          data: results.map((result) => result.campaign_type === 'story' ? result.instagram[2] : 0),
                        },
                        {
                          name: 'Instagram Post',
                          data: results.map((result) => result.campaign_type === 'post' ? result.instagram[2] : 0),
                        },
                        {
                          name: 'Instagram Reels',
                          data: results.map((result) => result.campaign_type === 'reel' ? result.instagram[2] : 0),
                        },
                        {
                          name: 'Tiktok Story',
                          data: results.map((result) => result.campaign_type === 'story' ? result.tiktok[2] : 0),
                        },
                        {
                          name: 'Tiktok Post',
                          data: results.map((result) => result.campaign_type === 'post' ? result.tiktok[2] : 0),
                        },
                        {
                          name: 'Tiktok Reels',
                          data: results.map((result) => result.campaign_type === 'reel' ? result.tiktok[2] : 0),
                        },
                      ]}
                      influencers={results.map((result) => result.name + " (" + result.date +")")}
                  />
                  <GraphCard
                      title="Total Video Plays"
                      numbers={[
                        {
                          name: 'Instagram Story',
                          data: results.map((result) => result.campaign_type === 'story' ? result.instagram[3] : 0),
                        },
                        {
                          name: 'Instagram Post',
                          data: results.map((result) => result.campaign_type === 'post' ? result.instagram[3] : 0),
                        },
                        {
                          name: 'Instagram Reels',
                          data: results.map((result) => result.campaign_type === 'reel' ? result.instagram[3] : 0),
                        },
                        {
                          name: 'Tiktok Story',
                          data: results.map((result) => result.campaign_type === 'story' ? result.tiktok[3] : 0),
                        },
                        {
                          name: 'Tiktok Post',
                          data: results.map((result) => result.campaign_type === 'post' ? result.tiktok[3] : 0),
                        },
                        {
                          name: 'Tiktok Reels',
                          data: results.map((result) => result.campaign_type === 'reel' ? result.tiktok[3] : 0),
                        },
                      ]}
                      influencers={results.map((result) => result.name + " (" + result.date +")")}
                  />
                  <GraphCard
                      title="Total Shares"
                      numbers={[
                        {
                          name: 'Instagram Story',
                          data: results.map((result) => result.campaign_type === 'story' ? result.instagram[6] : 0),
                        },
                        {
                          name: 'Instagram Post',
                          data: results.map((result) => result.campaign_type === 'post' ? result.instagram[6] : 0),
                        },
                        {
                          name: 'Instagram Reels',
                          data: results.map((result) => result.campaign_type === 'reel' ? result.instagram[6] : 0),
                        },
                        {
                          name: 'Tiktok Story',
                          data: results.map((result) => result.campaign_type === 'story' ? result.tiktok[6] : 0),
                        },
                        {
                          name: 'Tiktok Post',
                          data: results.map((result) => result.campaign_type === 'post' ? result.tiktok[6] : 0),
                        },
                        {
                          name: 'Tiktok Reels',
                          data: results.map((result) => result.campaign_type === 'reel' ? result.tiktok[6] : 0),
                        },
                      ]}
                      influencers={results.map((result) => result.name + " (" + result.date +")")}
                  />
                  <GraphCard
                      title="Total Saves"
                      numbers={[
                        {
                          name: 'Instagram Story',
                          data: results.map((result) => result.campaign_type === 'story' ? result.instagram[8] : 0),
                        },
                        {
                          name: 'Instagram Post',
                          data: results.map((result) => result.campaign_type === 'post' ? result.instagram[8] : 0),
                        },
                        {
                          name: 'Instagram Reels',
                          data: results.map((result) => result.campaign_type === 'reel' ? result.instagram[8] : 0),
                        },
                        {
                          name: 'Tiktok Story',
                          data: results.map((result) => result.campaign_type === 'story' ? result.tiktok[6] : 0),
                        },
                        {
                          name: 'Tiktok Post',
                          data: results.map((result) => result.campaign_type === 'post' ? result.tiktok[6] : 0),
                        },
                        {
                          name: 'Tiktok Reels',
                          data: results.map((result) => result.campaign_type === 'reel' ? result.tiktok[6] : 0),
                        },
                      ]}
                      influencers={results.map((result) => result.name + " (" + result.date +")")}
                  />
                </>
            ) : (
                <Typography variant="h5" align="center">
                  {" "}
                  Please select a campaign and influencer{" "}
                </Typography>
            )}
          </TabPanel>
        </Grid>
      </Grid>
  );
};

export default CampaignResult;
import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from '@mui/material';
import DELETE_CHATROOM from "../../graphql/mutations/deleteChatroom";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

function DeleteConfirmationDialog({ open, setOpen, chatroomId }) {
  const navigate = useNavigate();

  const handleClose = (e) => {
    e.stopPropagation();
    setOpen(false);
  };

  const [deleteChatroom] = useMutation(DELETE_CHATROOM, {
    onCompleted: () => {
      toast.success("Chat Deleted Successfully");
      navigate("/chat");
    },
  });

  const handleDelete = (id) => {
    deleteChatroom({
      variables: {
        chatroomId: id
      },
    });
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this chat? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={() => handleDelete(chatroomId)} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}


export default DeleteConfirmationDialog;

import { useMutation, useQuery } from "@apollo/client";
import CREATE_TASK from "../../graphql/mutations/createTask";
import UPDATE_TASK from "../../graphql/mutations/updateTask";
import GET_INFLUENCERS_FOR_TASKS from "../../graphql/queries/getInfluencersForTask";
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useState, useEffect } from "react";

import moment from 'moment';

import {
  Box,
  Button,
  Grid,
  Tabs,
  Tab,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

const TaskModal = ({ open, handleClose, campaignId, task, setTaskMade, refetchQuery, ...props }) => {
  const [createTask] = useMutation(CREATE_TASK, {
    onCompleted: () => {
      setTaskMade(true)
      handleClose(false);
    },
    refetchQueries: [ refetchQuery],
  });

  const [updateTask] = useMutation(UPDATE_TASK, {
    onCompleted: () => {
      handleClose(false);
    },
    refetchQueries: [refetchQuery],
  });
  const [selectedInfluencer, setSelectedInfluencer] = useState("");
  const [taskDescription, setTaskDescription] = useState("");
  const [dueDate, setDueDate] = useState(moment())


  useEffect(() => {
    if (task && task.description) {
      setTaskDescription(task.description);
    }
    if (task && task.dueDate) {
      setDueDate(moment.utc(task.dueDate));
    }
  }, [task]); 

  const { data: myInfluencers } = useQuery(GET_INFLUENCERS_FOR_TASKS, {
    variables: {
      campaignId: campaignId,
    },
  });

  const handleTaskCreation = () => {
    createTask({
      variables: {
        contractId: selectedInfluencer.id,
        description: taskDescription,
        dueDate: dueDate,
      },
    });
    setTaskDescription("");
    setDueDate(moment());
  };

  const handleTaskUpdation = () => {
    updateTask({
      variables: {
        id: task?.id,
        description: taskDescription,
        dueDate: dueDate,
      },
    });
    setTaskDescription("");
    setDueDate(moment())
  };

  const handleCancel = () => {
    handleClose(false);
    setTaskDescription("");
    setDueDate(moment());
  };



  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      {...props}
    >
      { task?.id ?(<DialogTitle id="form-dialog-title">
        Edit A Task For Influencer
        </DialogTitle>) : (
        <DialogTitle id="form-dialog-title">
          Create A Task For Influencer
        </DialogTitle>
      )
     }


      <DialogContent>
        { !task?.id &&
          (<Box sx={{ minWidth: 300, marginRight: 2 }}>
            <FormControl fullWidth variant="standard">
              <InputLabel
                id="select-campaign-label"
                sx={{
                  color: "rgba(239, 46, 131, 1)",
                  fontSize: "1.2rem !important",
                }}
              >
                Select Influencer
              </InputLabel>
              <Select
                labelId="select-campaign-label"
                id="select-campaign"
                value={selectedInfluencer}
                label="Campaign"
                variant="standard"
                onChange={(e) => setSelectedInfluencer(e.target.value)}
              >
                {myInfluencers?.getContractedInfluencers.map((influencer) => (
                  <MenuItem value={influencer} key={"campaign-" + influencer.id}>
                    {influencer.influencer?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          )}
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <div style={{marginTop:"15px", marginBottom:"15px"}}>
              <DatePicker
                label="Deadline Date"
                value={dueDate}
                format="DD/MM/YYYY"
                onChange={(newValue) => setDueDate(newValue)}
                slotProps={{ textField: { variant: 'standard', fullWidth: true, } }}
              />
            </div>
          </LocalizationProvider>

        <TextField
          autoFocus
          margin="dense"
          id="task-description"
          label="Task Description"
          type="text"
          fullWidth
          placeholder="Enter the description of the task being assigned for the influencer"
          multiline
          rows={5}
          value={taskDescription}
          onChange={(e) => setTaskDescription(e.target.value)}
        />
      </DialogContent>
      <DialogActions sx={{ display: "flex", justifyContent: "space-between" }}>
        <Button onClick={() => handleCancel()} color="primary">
          Cancel
        </Button>
          { task?.id ? (<Button
            onClick={handleTaskUpdation}
            color="primary"
            disabled={taskDescription === ""}
          >
            Update Task
          </Button>
        ): (<Button
            onClick={handleTaskCreation}
            color="primary"
            disabled={selectedInfluencer === "" || taskDescription === ""}
          >
            Assign Task
          </Button>)}
      </DialogActions>
    </Dialog>
  );
};


export default TaskModal
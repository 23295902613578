import { gql } from '@apollo/client';

export const UPDATE_CHATROOM_STATUS = gql`
  mutation UpdateChatroomStatus($chatroomId: ID!, $active: Boolean!) {
    updateChatroomStatus(chatroomId: $chatroomId, active: $active) {
      id
      active
    }
  }
`;

export default UPDATE_CHATROOM_STATUS

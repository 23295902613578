import React, { useEffect, useState } from "react";
import {
  Button,
  ButtonGroup,
  Grid,
  Typography,
  Select,
  ToggleButtonGroup,
  Box,
  MenuItem,
  FormControl,
  ToggleButton,
  InputLabel,
  Avatar,
} from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { styled } from "@mui/material/styles";
import ReactPaginate from "react-paginate";
import GET_MY_CAMPAIGNS from "../../graphql/queries/getMyCampaigns";
import TIKTOK_ICON from "../../assets/icon/Tiktok.png";
import INSTAGRAM_ICON from "../../assets/icon/Instagram.png";
import Sidebar from "./Sidebar";
import InfluencerCard from "./InfluencerCard";
import "./Style.css";
import { useLazyQuery, useQuery, useMutation } from "@apollo/client";
import INFLUENCER_GALLERY_LISTING from "../../graphql/queries/getInfluencersForGallery";
import INVITE_TO_CAMPAIGN from "../../graphql/mutations/inviteToCampaign";
import PopupNotification from "../common-components/popupNotification";
import InfluencerPopup from "../common-components/influencerPopup";
import ChatBanner from "../../assets/gallery-banner.jpeg"
import { makeStyles } from "@mui/styles";

const MuiToggleButton = styled(ToggleButton)({
  "&.MuiToggleButton-root:hover": {
    color: "white",
    backgroundColor: "#FFA3C7",
  },
  "&.Mui-selected, &.Mui-selected:hover": {
    color: "white",
    backgroundColor: "#e83e8c",
  },
});
const currentUser = localStorage.getItem("currentUser");

const Influencer = () => {
  const [influencerList, setInfluencerList] = useState([]);
  const [campaignList, setCampaignList] = useState([]);
  const [sidebarActive, setSidebarActive] = useState(false);
  const [sortOrder, setSortOrder] = useState("newest");

  const [getBrandsFVT, setGetBrandsFVT] = useState(() => {
    try {
      const cartList = localStorage.getItem("cartList");
      return cartList ? JSON.parse(cartList) : {};
    } catch (error) {
      console.error("Failed to parse cartList:", error);
      return {};
    }
  });

  const [selectedCampaign, setSelectedCampaign] = useState('All');
  const [showInterestedInfluencers, setShowInterestedInfluencers] =
    useState(false);
  const [myFavorites, setMyFavorites] = useState(() => {
  try {
    const cartList = localStorage.getItem("cartList");
    return cartList ? Object.keys(JSON.parse(cartList)) : [];
  } catch (error) {
    console.error("Failed to parse cartList:", error);
    return [];
  }
});

  const [notification, setNotification] = useState({
    show: false,
    notificationText: "",
  });
  const [socialFilter, setSocialFilter] = useState("All");
  const [selectedInfluencer, setSelectedInfluencer] = useState(null);
  let filteredInfluencerList = [];
  const itemsPerPage = 12;
  influencerList.forEach((influencer) => {
    if (showThisProfile(influencer) && influencer.userGalleries?.length > 0) {
      filteredInfluencerList.push(influencer);
    }
  });

  const [itemOffset, setItemOffset] = useState(0);

  const endOffset = itemOffset + itemsPerPage;
  const currentItems = filteredInfluencerList.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(filteredInfluencerList.length / itemsPerPage);

  const [inviteToCampaign] = useMutation(INVITE_TO_CAMPAIGN, {
    onCompleted: (res) => {
      setGetBrandsFVT({});
      setNotification({
        show: true,
        notificationText: "Invite Successful",
      });
    },
    refetchQueries: ["getInvitedInfluencers"],
  });
  useQuery(GET_MY_CAMPAIGNS, {
    variables: {
      userId: localStorage.getItem("currentUser"),
    },
    onCompleted: (res) => {
      setCampaignList(res.getMyCampaigns);
    },
  });
  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % filteredInfluencerList.length;
    setItemOffset(newOffset);
  };

  const [getInfluencers] = useLazyQuery(INFLUENCER_GALLERY_LISTING, {
    onCompleted: (res) => {
      setInfluencerList(res.getInfluencersForGallery);
    },
  });

  useEffect(() => {
    getInfluencers({
      variables: { userId: localStorage.getItem("currentUser"), showInterested: showInterestedInfluencers, filterByCampaign: selectedCampaign },
    });
  }, [socialFilter, showInterestedInfluencers, currentUser, selectedCampaign]);

  const handleSubmit = (campaignId) => {
    inviteToCampaign({
      variables: {
        campaignId: campaignId,
        influencerId: Object.keys(getBrandsFVT),
        description: "To be discussed",
      },
    });
  };

  const handleHeart = (id, item) => {
    let data = { ...getBrandsFVT };
    if (data[id]) {
      delete data[id];
    } else {
      data[id] = item;
    }

    if (myFavorites.includes(id)) {
      const fav = [...myFavorites];
      const index = fav.indexOf(id);
      if (index > -1) {
        fav.splice(index, 1);
      }
      setMyFavorites(fav);
    } else {
      const fav = [...myFavorites, id];
      setMyFavorites(fav);
    }

    setGetBrandsFVT(data);
    localStorage.setItem("cartList", JSON.stringify(data));

  };

  const handleCampaignChange = (event) => {
    setSelectedCampaign(event.target.value);
  };

  function showThisProfile(influencer) {
    if (showInterestedInfluencers) {
      if (socialFilter === "All") return true;
      if (socialFilter === "Instagram" && influencer.instagramStat === null)
        return false;
      if (socialFilter === "TikTok" && influencer.tiktokStat === null)
        return false;

      return true;
    }

    if (!showInterestedInfluencers) {
      if (socialFilter === "All") return true;
      if (socialFilter === "Instagram" && influencer.instagramStat === null)
        return false;
      if (socialFilter === "TikTok" && influencer.tiktokStat === null)
        return false;

      return true;
    }

    return false;
  }

  const handleDeselect = () => {
    setGetBrandsFVT({});
    setMyFavorites([]);
    localStorage.setItem("cartList", '');
  };

  const useStyles = makeStyles({
    banner: {
      height: "25vh",
      padding: "20px 120px 20px 20px",
      width: "100%",
      backgroundImage: `url(${ChatBanner})`, // Correct usage of a template literal because it's a JS object
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 5px",
      color: "#2d3436",
      objectFit: "contain"
    }
  });

  const classes = useStyles();

  const sortItems = (currentItems) => {
    return [...currentItems].sort((a, b) => {
      if (sortOrder === "newest") {
        return (
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );
      } else {
        return (
            new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
        );
      }
    });
  };

  return (
    <Grid xs={12} sx={{ padding: "12px 12px 12px 70px" }}>
      <Box className={classes.banner}/>
      <Grid
        xs={12}
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{backgroundColor:"#FFA3C7", marginTop:"5px", border: "2px solid black", borderRadius: "15px"}}
      >
        <Typography variant="h5" sx={{ mb: 2, ml: 2 }}>
          Influencers
        </Typography>
        <Grid
          item
          xs={12}
          lg={9}
          container
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
        >
          <Grid item wrap="wrap" sx={{ pt: 1, pb: 1, mr: 1 }}>
            <ButtonGroup
              variant="outlined"
              aria-label="filter-interested-influencers"
            >
              <Button
                variant="text"
                onClick={() => {
                  socialFilter === "Instagram"
                    ? setSocialFilter("All")
                    : setSocialFilter("Instagram");
                }}
              >
                <Avatar src={INSTAGRAM_ICON} sx={{ m: 0 }} />
              </Button>
              <Button
                variant="text"
                onClick={() => {
                  socialFilter === "TikTok"
                    ? setSocialFilter("All")
                    : setSocialFilter("TikTok");
                }}
              >
                <Avatar src={TIKTOK_ICON} sx={{ m: 0 }} />
              </Button>
            </ButtonGroup>
          </Grid>
          {campaignList && (
            <Grid
              item
              wrap="wrap"
              sx={{ minWidth: 220, width: "40%", pt: 1, pb: 1, mr: 1 }}
            >
              <Box>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Campaigns
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedCampaign}
                    label="Age"
                    variant="standard"
                    onChange={handleCampaignChange}
                  >
                    <MenuItem value="All">All</MenuItem>
                    {campaignList.map(
                      (campaign) =>
                        campaign.status === "Approved" && (
                          <MenuItem value={campaign.id} key={campaign.name}>
                            {campaign.name}
                          </MenuItem>
                        )
                    )}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
          )}
          <Grid item wrap="wrap">
            <ToggleButtonGroup
              exclusive
              aria-label="filter-interested-influencers"
            >
              <MuiToggleButton
                selected={!showInterestedInfluencers}
                aria-label="all-influencers"
                onClick={() => setShowInterestedInfluencers(false)}
              >
                All
              </MuiToggleButton>
              <MuiToggleButton
                selected={showInterestedInfluencers}
                aria-label="interested-influencers"
                onClick={() => setShowInterestedInfluencers(true)}
              >
                Interested
              </MuiToggleButton>
            </ToggleButtonGroup>
          </Grid>
          <Button
            size="large"
            onClick={() => setSidebarActive(true)}
            sx={{ color: "#fff", backgroundColor: "#e83e8c", ml: 1, py: 1.5 }}
          >
            <ShoppingCartIcon />
          </Button>
        </Grid>
      </Grid>
      <>
        <Box
            display="flex"
            justifyContent="flex-end"
            mt={2}
        >
          <Select
              value={sortOrder}
              sx={{fontSize: '15px', marginBottom: '10px'}}
              onChange={(event) => setSortOrder(event.target.value)}
          >
            <MenuItem value={"newest"} sx={{fontSize: '15px'}}>Newest</MenuItem>
            <MenuItem value={"oldest"} sx={{fontSize: '15px'}}>Oldest</MenuItem>
          </Select>
        </Box>
        <Grid
            item
            xs={12}
            container
            direction="row"
            justifyContent="space-around"
            sx={{
              mt: 5,
              marginBottom: 5,
              justifyContent: "flex-start",
              maxWidth: "100%",
              overflow: "hidden",
              flexWrap: "wrap"
            }}
        >
          {sortItems(currentItems).map((influencer) => (
            <InfluencerCard
              key={"authinf" + influencer.id}
              influencer={influencer}
              profilePic={
                influencer.image ||
                "https://mir-s3-cdn-cf.behance.net/user/230/110659178842.5c1b604bb2cea.jpg"
              }
              originGallery={true}
              selectedInfluencer={() => setSelectedInfluencer(influencer.id)}
              handleLike={() => handleHeart(influencer.id, influencer)}
              isLiked={myFavorites.includes(influencer.id)}
            />
          ))}
        </Grid>
        <Grid item xs={12}>
          <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              onPageChange={handlePageClick}
              pageRangeDisplayed={1}
              marginPagesDisplayed={1}
              pageCount={pageCount}
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="pagination__link"
              nextClassName="page-item"
              nextLinkClassName="pagination__link"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              containerClassName="pagination"
              activeClassName="pagination__link--active"
              renderOnZeroPageCount={null}
          />
        </Grid>
      </>
      {document.getElementById("container")}
      <Sidebar
        state={sidebarActive}
        campaignList={campaignList}
        close={() => setSidebarActive(false)}
        list={getBrandsFVT}
        handleHeart={(e, id, item) => handleHeart(id, item)}
        handleSubmit={handleSubmit}
        handleDeselect={handleDeselect}
      />
      <PopupNotification
        open={notification.show}
        notificationText={notification.notificationText}
        type={"success"}
      />
      {selectedInfluencer && (
        <InfluencerPopup
          influencerId={selectedInfluencer}
          nextInfluencer={() => console.log("I will do something")}
          closePopup={() => setSelectedInfluencer(null)}
          campaignId={selectedCampaign.id}
        />
      )}
    </Grid>
  );
};

export default Influencer;

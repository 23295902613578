import { gql } from "@apollo/client";

const GET_TASK_SUBMISSIONS = gql`
  query brandGetTaskSubmissions($campaignId: ID!) {
    brandGetTaskSubmissions(campaignId: $campaignId) {
      id
      customContract
      campaignTasks {
        id
        description
        imageSubmissions
        videoSubmissions
        status
        campaignContractId
        createdAt
        updatedAt
        dueDate
      }
      influencer {
        id
        image
        name
      }
    }
  }
`;

export default GET_TASK_SUBMISSIONS;

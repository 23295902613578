import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Grid,
  Tabs,
  Tab,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useMutation, useQuery } from "@apollo/client";
import GET_INFLUENCERS_WITH_TASKS from "../../../graphql/queries/getInfluencersWithTasks";
import DELETE_TASK from "../../../graphql/mutations/deleteTask";
import moment from 'moment';
import TaskModal from '../taskModal'
const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`influencer-tabpanel-${index}`}
      aria-labelledby={`influencer-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const TableHeaders = (campaignStarted) => {
  return (
    <TableHead>
      <TableRow>
        <TableCell>Influencer</TableCell>
        <TableCell align="center">Task Description</TableCell>
        <TableCell align="center">Task Deadline</TableCell>
        {!!campaignStarted && (<TableCell align="center">Actions</TableCell>)}
        {!campaignStarted && <TableCell align="center">Accept</TableCell>}
        {!campaignStarted && <TableCell align="center">Reject</TableCell>}
        {!campaignStarted && (
          <TableCell align="center">View Submissions</TableCell>
        )}
      </TableRow>
    </TableHead>
  );
};

const UserInfo = ({ src, name }) => {
  return (
    <Grid item xs container alignItems="center">
      {src ? (
        <img src={src} width="50" alt="avatar" />
      ) : (
        <AccountCircleIcon fontSize="large" sx={{ color: "#FFA3C7" }} />
      )}
      <Typography variant="subtitle1" sx={{ marginLeft: 2 }}>
        {name}
      </Typography>
    </Grid>
  );
};

const InfluencerTasks = ({
  campaignId,
  setTaskMade,
  invoiceAccepted,
  campaignStarted,
}) => {
  const [taskModal, openTaskModal] = useState(false);
  const [openTask, setOpenTask] = useState();
  const { data } = useQuery(GET_INFLUENCERS_WITH_TASKS, {
    variables: {
      campaignId: campaignId,
    },
    onCompleted: (res) => {
      if(res.getInfluencersWithTasks.length > 0){
        setTaskMade(true);
      }
    },
  });

  const [deleteTask] = useMutation(DELETE_TASK, {
    refetchQueries: ["getInfluencersWithTasks"]
  });

  const handleTask = (id) =>{
    deleteTask({
      variables: { taskId: id },
    })
  } 

  const formatDate = (date) =>{
    return moment.utc(date).format('DD/MM/YYYY')
  }

  return (
    <Grid
      item
      xs={12}
      container
      justifyContent="center"
      sx={{ marginBottom: 2 }}
    >
      <Grid item xs={12} container justifyContent="space-between">
        <Tabs
          value={0}
          aria-label="influencer-listing-switch"
          sx={{ maxWidth: 450 }}
        >
          <Tab label="Campaign Tasks" />
        </Tabs>
        {!campaignStarted && (
          <Button variant="contained" onClick={() =>{
            setOpenTask("")
            openTaskModal(true)
          }}>
            Create Task
          </Button>
        )}
      </Grid>
      <Grid item xs={12}>
        <TabPanel value={0} index={0}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHeaders campaignStarted={campaignStarted} />
              {data && (
                <TableBody>
                  {data?.getInfluencersWithTasks?.map((row, index) =>
                    row.campaignTasks.map((task) => (
                      <TableRow
                        key={
                          "interested-influencer" + row.id + "-" + row.influencer?.id
                        }
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          style={{ verticalAlign: "center", maxWidth: 200 }}
                        >
                          {
                            <UserInfo
                              src={row.influencer?.image}
                              name={row.influencer?.name}
                            />
                          }
                        </TableCell>
                        <TableCell align="center" style={{overflowWrap: "anywhere", whiteSpace: "pre-wrap"}}>{task?.description}</TableCell>
                        <TableCell align="center" style={{overflowWrap: "anywhere", whiteSpace: "pre-wrap"}}>{formatDate(task?.dueDate)}</TableCell>
                        <TableCell align="center">
                          <Button
                            onClick={() =>{
                              openTaskModal(true)
                              setOpenTask(task)
                            }
                            }>
                            Edit
                          </Button>

                          <Button
                            onClick={() =>
                              handleTask(task.id)
                            }>
                            Delete
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </TabPanel>
      </Grid>
      {!campaignStarted && (
        <TaskModal
          campaignId={campaignId}
          open={taskModal}
          handleClose={openTaskModal}
          task={openTask}
          setTaskMade={setTaskMade}
          refetchQuery="getInfluencersWithTasks"
        />
      )}
    </Grid>
  );
};

export default InfluencerTasks;
import { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Button, DialogContent } from "@mui/material";
import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ContractPopup = ({ contract, campaignContracts, campaignCustomContract }) => {
  const [openModal, setOpenModal] = useState(false);
  const [customContract, setCustomContract] = useState();
  
  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const loadCampaignContract = async () => {
    await fetch(
      campaignCustomContract
    ).then((r) => {
      r.text().then((d) => {
        setCustomContract(d);
      });
    });
  };


  useEffect(() => {
    loadCampaignContract();
  }, []);
  

  return (
    <div>
      <Button fullWidth variant="contained" onClick={handleOpenModal}>
        Show Contracts
      </Button>
      <Dialog
        open={openModal}
        TransitionComponent={Transition}
        keepMounted
        maxWidth="300px"
        onClose={handleCloseModal}
      >
        <DialogTitle>Contract Details</DialogTitle>
        <DialogContent>
          {campaignContracts.map((contract) => (
            <InvoiceTable key={contract.id} campaignContract={contract} />
          ))}
          <div dangerouslySetInnerHTML={{ __html: contract }} />


          { customContract && (
            <div style={{width: "100%"}}>
            <b>
              <br/>
              Additional Clauses by brand
            </b>
            <div dangerouslySetInnerHTML={ {__html: customContract} || { __html: 'Loading...' }} />
            </div>
            ) 
          }


        </DialogContent>

        <DialogActions>
          <Button onClick={handleCloseModal}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const InvoiceTable = ({ campaignContract }) => {
  const { description, finalFee, platformFee } = campaignContract;
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Influencer Quote</TableCell>
            <TableCell>Fee</TableCell>
            <TableCell>Payable Fee</TableCell>
            <TableCell>Fee Payable to Agency</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
            <TableCell style={{ wordBreak: "break-word" }}>
              {description}
            </TableCell>
            <TableCell>£{finalFee}</TableCell>
            <TableCell>£{(finalFee * platformFee) / 100}</TableCell>
            <TableCell>£{finalFee - (finalFee * platformFee) / 100}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ContractPopup;

import {
  Grid,
  FormControl,
  Typography
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import useWindowDimensions from "../CustomHooks/windowDimensions";
import { MenuProps, useStyles, options } from "./utils";
import { useQuery } from "@apollo/client";
import GET_ALL_CAMPAIGNS from "../../graphql/queries/getAllCampaigns";
import CampaignSelectDropdown from "./../sharedComponents/campaignSelectDropdown";
import BrandSelectDropdown from "../sharedComponents/brandSelectDropdown";
import GET_BRANDS from "../../graphql/queries/getBrands";
import PublishButton from '../sharedComponents/publishButton';
import UnpublishButton from "../sharedComponents/unpublishButton";

const chartOptions = {
  chart: {
    foreColor: "#fff",
    type: "bar",
    stacked: false,
  },
  toolbar: {
    show: false,
  },
  responsive: [
    {
      breakpoint: 480,
      options: {
        legend: {
          position: "bottom",
          fontSize: "16px",
          offsetX: -10,
          offsetY: 0,
        },
      },
    },
  ],
  plotOptions: {
    bar: {
      horizontal: true,
    },
  },
  tooltip: {
    theme: "dark",
  },
  legend: {
    position: "right",
    offsetY: 40,
    fontSize: "16px",
    color: "white",
  },
  fill: {
    opacity: 1,
  },
};

const CampaignComparisonChart = (props) => {
  const classes = useStyles();
  const { height, width } = useWindowDimensions();
  const [series, setSeries] = useState([]);
  const [updatedOptions, setUpdatedOptions] = useState({});
  const [selected, setSelected] = useState([]);
  const [brands, setBrands] = useState([]);
  const currentUserId = localStorage.getItem('currentUser');
  const [selectedBrand, setSelectedBrand] = useState(currentUserId);
  const [campaigns, setCampaigns] = useState([]);
  const { userRole, chart } = props;
  const [selectedCampaignIds, setSelectedCampaignIds] = useState([])

  const { allCampaignsData, refetch } = useQuery(GET_ALL_CAMPAIGNS, {
    variables: {
     userId: selectedBrand,
     userRole: userRole,
     graphId: chart?.id
    },
    onCompleted: (res) => {
      setCampaigns(res.getAllCampaigns);
    }
  });

  const { data: allBrandsData } = useQuery(GET_BRANDS, {
    skip: userRole !== 'Admin',
    onCompleted: (response) => {
      if (userRole === 'Admin') {
        setBrands(response.getBrands);
      }
    },
  });

  useEffect(() => {
    let checkedCampaigns = campaigns?.filter((campaign) => selected.includes(campaign.name));
    setSelectedCampaignIds(checkedCampaigns.map((campaign => campaign.id)));
    let campaignResultData = {}

    if (checkedCampaigns?.length > 0) {
      for (let i = 0; i < checkedCampaigns.length; i++) {
        const campaign = checkedCampaigns[i];
        const key = campaign.name;

        if (!campaignResultData[key]) {
          campaignResultData[key] = {
            totalReach: 0,
            totalLikes: 0,
            totalComments: 0,
            totalFollowers: 0,
          };
        }

        for (const currentResult of campaign.campaignResults) {
          campaignResultData[key].totalReach +=
            currentResult?.instaAccountsReached + currentResult?.tiktokPlays;
          campaignResultData[key].totalLikes +=
            currentResult?.tiktokLikes + currentResult?.instaLikes;
          campaignResultData[key].totalComments +=
            currentResult?.instaComments + currentResult?.tiktokComments;
          campaignResultData[key].totalFollowers +=
            currentResult?.tiktokFollowers + currentResult?.instaFollowers;
        }
      }
    }

    setUpdatedOptions({
      ...chartOptions,
      xaxis: {
        categories: selected?.map((campaign) => campaign),
        labels: {
          formatter: function (value) {
            return shortNum(value, 0);
          },
        },
      },
    });

    setSeries([
      {
        name: "Total Reach",
        data: selected.map(
          (campaign) => campaignResultData[campaign]?.totalReach
        ),
      },
      {
        name: "Total Likes",
        data: selected.map(
          (campaign) => campaignResultData[campaign]?.totalLikes
        ),
      },
      {
        name: "Total Comments",
        data: selected.map(
          (campaign) => campaignResultData[campaign]?.totalComments
        ),
      },
      {
        name: "Total Followers",
        color: "#9b59b6",
        data: selected.map(
          (campaign) => campaignResultData[campaign]?.totalFollowers
        ),
      },
    ]);
  }, [selected]);

  useEffect(() => {
    if (selectedBrand) {
      refetch()
    }
  }, [selectedBrand, refetch])

  const shortNum = (number, decimal) => {
    decimal = Math.pow(10, decimal);

    var short = ["k", "m", "b"];

    for (var i = short.length - 1; i >= 0; i--) {
      var size = Math.pow(10, (i + 1) * 3);

      if (size <= number) {
        number = ((number * decimal) / size / decimal).toFixed(1);

        if (number === 1000 && i < short.length - 1) {
          number = 1;
          i++;
        }

        number += short[i];
        break;
      }
    }

    return number;
  };

  const handleBrandChange = (event) => {
    const value = event.target.value;
    setSelectedBrand(value);
  }

  const handleSelectionChange = (selectedCampaigns) => {
    setSelected(selectedCampaigns);
  };

  return (
    <Grid item xs container justifyContent="start">
      <Grid item xs={12} container justifyContent="space-between">
        <Typography variant="h5">
          Campaign Comparison
        </Typography>
        <FormControl className={classes.formControl}>
          {userRole == "Admin" && (
            <BrandSelectDropdown
              labelId="brand-select-label"
              brands={brands}
              selectedBrand={selectedBrand}
              handleChange={handleBrandChange}
              />
            )
          }
          <div style={{display: "flex", alignItems: 'center', marginTop: 10}}>
            <CampaignSelectDropdown
              campaigns={campaigns}
              onSelectionChange={handleSelectionChange}
              classes={classes}
              MenuProps={MenuProps}
            />
            <div style={{justifyContent: 'space-between',  display: 'flex' }}>
              <div style={{ flex: 1, margin: 5 }}>
                <PublishButton
                  graphId={chart?.id}
                  userId={selectedBrand}
                  campaignIds={selectedCampaignIds}
                  userRole={userRole}
                />
              </div>
              <div style={{flex: 1, margin: 5}}>
                <UnpublishButton
                  graphId={chart?.id}
                  userId={selectedBrand}
                  campaignIds={selectedCampaignIds}
                  userRole={userRole}
                />
              </div>
            </div>
          </div>
        </FormControl>
      </Grid>
      <Chart
        options={updatedOptions}
        series={series}
        type="bar"
        height={height / 1.65}
        width={width - 300}
      />
    </Grid>
  );
};

export default CampaignComparisonChart;

import { useQuery } from "@apollo/client";
import GET_BRAND_CHATROOMS from "../../graphql/queries/getBrandChatrooms";
import React, { useState } from "react";
import {
  Paper,
  Grid,
  Divider,
  List,
  Avatar,
  CardHeader,
  IconButton,
  Box
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { CircularLoader } from "../common-components/loaders";
import ChatRoomTile from "./chatroomTile";
import Chatroom from "./chatroom";
import useWindowDimensions from "../CustomHooks/windowDimensions";
import useMediaQuery from '@mui/material/useMediaQuery';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ChatBanner from "../../assets/chatroom-banner.jpeg"

const useStyles = makeStyles({
  table: {
    minWidth: 750,
    width: "100%",
  },
  chatSection: {
    width: "100%",
    height: "89vh",
  },
  headBG: {
    backgroundColor: "#e0e0e0",
  },
  borderRight500: {
    borderRight: "1px solid #e0e0e0",
    paddingRight: '5px',
    paddingLeft: '5px'
  },
  messageArea: {
    height: "70vh",
    overflowY: "auto",
  },
  banner: {
    height: "35vh",
    padding: "20px 120px 20px 20px",
    width: "100%",
    backgroundImage: `url(${ChatBanner})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 5px",
    color: "#2d3436",
    marginBottom: 30,
    objectFit: "contain"
  },
});

const Chat = () => {
  const classes = useStyles();
  const [chatrooms, setChatrooms] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  const { height, width } = useWindowDimensions();
  const [currentInfluencer, setCurrentInfluencer] = useState([])
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const [sidebarOpen, setSidebarOpen] = useState(true);

  useQuery(GET_BRAND_CHATROOMS, {
    variables: {
      userId: localStorage.getItem("currentUser"),
    },
    onCompleted: (res) => {
      setChatrooms(res.getBrandChatrooms);
    },
  });

  const handleChatSelection = (chatId, influencer) => {
    setCurrentInfluencer(influencer);
    setSelectedChat(chatId);
    // setSidebarOpen(!sidebarOpen);
  };

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  if (chatrooms.length === 0)
    return <CircularLoader title="No Chats Available" />;
  return (
    <Grid xs={12} pl={10} pt={2} pr={2}>
      <Box className={classes.banner}/>
      <Grid xs={12} container component={Paper} className={classes.chatSection}>
        {sidebarOpen && (
          <Grid item xs={sidebarOpen ? 4 : 1} className={classes.borderRight500}>
            <CardHeader
              sx={{backgroundColor: "#FFA3C7"}}
              avatar={
                <Avatar
                  alt={chatrooms[0].brand.name}
                  src={chatrooms[0].brand.image}
                />
              }
              action={selectedChat &&
                <IconButton
                  onClick={toggleSidebar}
                 >
                  {sidebarOpen ? <ArrowBackIosIcon /> : <ArrowForwardIosIcon />}
                </IconButton>
              }
              title={!isSmallScreen && chatrooms[0].brand.name}
            />
            <Divider />
            <List sx={{ overflowY: 'auto', maxHeight: 'calc(100vh - 200px)' }}>
              {chatrooms.map((chatroom, index) => (
                <ChatRoomTile
                  influencerId={chatroom.participiantId}
                  selectChat={(chatroomId, influencer) => handleChatSelection(chatroom.id, influencer)}
                  key={`${chatroom.participiantId}-${chatroom.id}-${index}`}
                  campaignName={chatroom.campaign.name}
                  campaignType={chatroom.campaign.campaignType}
                  selected={chatroom.id === selectedChat}
                  chatroom={chatroom}
                />
              ))}
            </List>
          </Grid>
        )}
        {selectedChat &&
          <Chatroom id={selectedChat} influencer={currentInfluencer}
            sidebarOpen={sidebarOpen} handleSidebar={toggleSidebar}
            chatroom={chatrooms.find((chatroom) => chatroom.id == selectedChat)}
          />
        }
      </Grid>
    </Grid>
  );
};

export default Chat;

import React, { useRef, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  CircularProgress
} from "@mui/material";
import {
  CircleStencil,
  FixedCropper,
  ImageRestriction,
} from "react-advanced-cropper";
import S3 from "aws-s3";
import "react-advanced-cropper/dist/style.css";
import config from "../../config/s3_config";
import { useMutation } from "@apollo/client";
import SEND_MESSAGE from "../../graphql/mutations/sendMessage";
import {imageQuality} from "../sharedComponents/imageQuality"

const S3Client = new S3(config);

export const ChatAttachmentUploader = ({ open, setOpen, currentUserId, chatroomId }) => {
  const cropperRef = useRef(null);
  const [attachment, setAttachment] = useState("");
  const [message, setMessage] = useState("");
  const [disableActions, setDisableActions] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [imgData, setImgData] = useState(null);
  const [file, setFile] = useState("");
  const fileTypes = "image/jpeg, image/jpg, image/png, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/pdf, .mp4, .mov, .avi, .wmv, .flv, .mkv, .webm, .m4v, .mpeg, .mpg, .ogv, .3gp, .3g2"

  const [sendMessage] = useMutation(SEND_MESSAGE, {
    refetchQueries: ["getChatroomMessages"],
  });

  const handleSave = async () => {
    setIsLoading(true)
    setDisableActions(true);

    S3Client.uploadFile(file, file.name, {
      ContentDisposition: `attachment; filename="${file.name}"`
    }).then((data) => {
      setDisableActions(true);
      sendMessage({
        variables: {
          chatroomId: chatroomId,
          userId: currentUserId,
          message: message,
          attachment: data?.location,
        },
      });
      setAttachment("");
      setMessage("");
      setOpen(false)
      setIsLoading(false)

    })
        .catch((err) => {
          setIsLoading(false)
          console.error('Error uploading attachment:', err);
        });
  };

  const handleClose = () => {
    setOpen(false);
    setIsLoading(false)
  };

  const onChangePicture =  async (e) => {
    if (e.target.files[0]) {
      if (e.target.files[0].type?.includes('image')) {
        const isGoodQuality = await imageQuality(e.target.files[0]);
        if (!isGoodQuality) {
          alert('Please upload a better quality image file.');
        }
        else {
          handleFileUpload(e.target.files[0])
        }
      }
      else{
        handleFileUpload(e.target.files[0])
      }
    }
  };

  const handleFileUpload = (file) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      setImgData(reader.result);
    });
    reader.readAsDataURL(file);

    setFile(file);
    setDisableActions(true);
  }

  return (
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle align="center">Upload Attachment</DialogTitle>
        <DialogContent>
          <TextField
              label="Message Content"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              fullWidth
              margin="normal"
          />
          {file?.type?.includes("image") ? (
              <img src={imgData} width={'100%'} alt=""/>
          ) : (
              <div>
                <p>{file?.name}</p>
              </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button
              variant="contained"
              component="label"
              sx={{ marginRight: 4 }}
              disabled={disableActions}
          >
            Upload Attachment
            <input
                hidden
                accept={fileTypes}
                type="file"
                onChange={onChangePicture}
            />
          </Button>
          {isLoading && <CircularProgress sx={{ color: "#FFA3C7" }} /> }
          {!isLoading && <Button onClick={handleSave} disabled={!disableActions}>
            Send Message
          </Button>
          }
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
  );
};
import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Chart from "react-apexcharts";
import { isMobile } from "react-device-detect";

const chartWidth = isMobile ? 300 : 1500;
const GraphCard = ({
  title,
  influencers = [],
  numbers = [],
  chartType = "line",
}) => {
  const series = numbers;
  const options = {
    chart: {
      height: 350,
      type: "line",
      dropShadow: {
        enabled: true,
        color: "#000",
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2,
      },
      toolbar: {
        show: false,
      },
    },
    colors: ["#FFA3C7", "#545454"],
    dataLabels: {
      enabled: true,
    },
    stroke: {
      curve: "smooth",
    },
    grid: {
      borderColor: "#e7e7e7",
      row: {
        colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    markers: {
      size: 1,
    },
    xaxis: {
      categories: influencers,
    },
    yaxis: {
      min:
        Math.floor(Math.min(...numbers[0].data, ...numbers[1].data) / 100) *
          100 -
        200,
      max:
        Math.ceil(Math.max(...numbers[0].data, ...numbers[1].data) / 100) *
          100 +
        200,
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
      fontSize: "16px",
      floating: true,
      offsetY: -25,
      offsetX: -5,
    },
  };

  return (
    <Card sx={{ minWidth: 330, marginTop: 3 }}>
      <CardContent>
        <Typography variant="h5" gutterBottom textAlign="center">
          {title}
        </Typography>
        <Chart
          options={options}
          series={series}
          type={chartType}
          width={chartWidth}
          height={600}
        />
      </CardContent>
    </Card>
  );
};

export default GraphCard;

import React, { useState } from "react";
import {
  Grid,
  Typography,
  TableContainer,
  Paper,
  Stack,
  AvatarGroup,
  Avatar,
  Modal,
  Box,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useLazyQuery, useQuery } from "@apollo/client";
import GET_MY_SURVEYS from "../../graphql/queries/getMySurveys";
import GET_SURVEY_INFLUENCERS from "../../graphql/queries/getSurveyInfluencers";
import MiniInfluencerCard from "./miniInfluencerCard";
import CampaignBanner from "../../assets/creator-survey-banner-2.jpeg"
import { makeStyles } from "@mui/styles";
import SurveyPlaceholder from "./surveyPlaceholder";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90vw",
  height: "90vh",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 1,
  overflowY: "auto",
};

const useStyles = makeStyles({
  banner: {
    height: "25vh",
    padding: "5px 5px 5px 5px",
    width: "99%",
    backgroundImage: `url(${CampaignBanner})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
    color: "#2d3436",
    marginBottom: 40,
    objectFit:"contain"
  },
})

const CreatorSurvey = () => {
  const { data } = useQuery(GET_MY_SURVEYS, {
    variables: {
      userId: localStorage.getItem("currentUser"),
    },
  });

  
  const classes = useStyles();

  return (
    <Grid
      item
      xs={12}
      sx={{ paddingLeft: "80px", paddingTop: 5 }}
      container
      justifyContent="center"
    >
      <Box className={classes.banner}/>
        <Typography
          variant="h4"
          sx={{ width: "100%", marginBottom: 2 }}
          align="center"
        >
          Survey Responses
        </Typography>
        <SurveyPlaceholder data={data}/>

    </Grid>
  );
};

export default CreatorSurvey;
